import { mapActions } from "vuex";
export default {
  data: () => ({
    loading: {
      save: false
    },
    messages: {
      403: true,
      404: true,
      400: true
    }
  }),
  methods: {
    preSubmit() {
      return null;
    },
    postSubmit() {
      return null
    },
    getPath() {
      return null;
    },
    getMethod() {
      return 'POST';
    },
    getMessages() {
      return this.messages
    },
    async save() {
      this.notify({ status: false });

      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.notify({ message: this.$i18n.t("form_error") });
        return;
      }
      this.preSubmit();
      try {
        const response = await this.request({
          url: this.getPath(),
          data: this.getData(this.form),
          method: this.getMethod(),
          messages: this.getMessages()
        });
        this.$emit("success", response);
        this.resetForm();
      } catch (error) {
        this.$emit("error", error);
      }
      this.postSubmit();
    },
    getData(form) {
      const data = {};
      for (const prop in form) {
        if (form[prop] === null || typeof form[prop] === "undefined") continue;
        this.$utils.setPropertyValue(data, prop, form[prop]);
      }
      return data;
    },
    resetForm() {
      return;
    },
    ...mapActions({ request: "request", notify: "notification/notify" })
  }
};
