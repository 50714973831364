<template>
  <div>
    <v-form @submit.prevent="save">
      <v-card>
        <v-card-title>
          <div>{{ $t("account.profile.title") }}</div>
        </v-card-title>
        <v-card-subtitle class="grey--text text--darken-1">{{ $t("account.profile.description") }}</v-card-subtitle>
        <v-card-text class="mt-4">
          <v-text-field
            :label="$t('users.fields.first_name.title')"
            :placeholder="$t('users.fields.first_name.placeholder2')"
            outlined
            dense
            append-icon="mdi-account-tie-outline"
            v-model="form.firstName"
            @input="delayTouch($v.form.firstName)"
            @blur="$v.form.firstName.$touch"
            autocomplete="off"
            :error-messages="firstNameErrors"
          ></v-text-field>
          <v-text-field
            :label="$t('users.fields.last_name.title')"
            :placeholder="$t('users.fields.last_name.placeholder2')"
            outlined
            dense
            append-icon="mdi-account-tie-outline"
            v-model="form.lastName"
            @input="delayTouch($v.form.lastName)"
            @blur="$v.form.lastName.$touch"
            autocomplete="off"
            :error-messages="lastNameErrors"
          ></v-text-field>
          <v-text-field
            :label="$t('users.fields.email.title')"
            :placeholder="$t('users.fields.email.placeholder2')"
            outlined
            dense
            append-icon="mdi-at"
            v-model="form.email"
            @input="delayTouch($v.form.email)"
            @blur="$v.form.email.$touch"
            autocomplete="off"
            :error-messages="emailErrors"
          ></v-text-field>
          <v-text-field
            :label="$t('users.fields.phone.title')"
            :placeholder="$t('users.fields.phone.placeholder2')"
            outlined
            dense
            append-icon="mdi-phone-outline"
            v-model="form.phone"
            @input="delayTouch($v.form.phone)"
            @blur="$v.form.phone.$touch"
            autocomplete="off"
            :error-messages="phoneErrors"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn type="submit" :loading="loading.save" text color="primary">
            {{
            $t("btn.update")
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { required, maxLength, helpers, email } from "vuelidate/lib/validators";
import FormDelayTouchMixin from "./../../mixins/form-delay-touch";
import FormMixin from "./../../mixins/form";

export default {
  mixins: [FormDelayTouchMixin, FormMixin],
  created() {
    this.initForm();
  },
  data: () => ({
    form: {
      id: null,
      fisrtName: null,
      lastName: null,
      email: null,
      phone: null
    },
    loading: {
      save: false
    }
  }),
  validations: {
    form: {
      firstName: { required, maxLength: maxLength(100) },
      lastName: { required, maxLength: maxLength(100) },
      email: { maxLength: maxLength(100), email },
      phone: {
        matchPhone: val => !helpers.req(val) || /^[0-9]{1,20}$/.test(val)
      }
    }
  },
  methods: {
    initForm() {
      const user = this.$auth.user();
      this.form = user.profile;
    },
    getPath() {
      return "profile.update";
    },
    getData(form) {
      // eslint-disable-next-line no-unused-vars
      const { id, ...data } = form;
      return data;
    },
    preSubmit() {
      this.loading.save = true;
    },
    postSubmit() {
      this.loading.save = false;
      this.$auth.fetch();
    },
    getMessages() {
      return {
        200: this.$t("account.profile.edit.success"),
        400: true
      };
    }
  },
  computed: {
    firstNameErrors() {
      const errors = [];
      if (!this.$v.form.firstName.$dirty) return errors;

      if (!this.$v.form.firstName.required)
        errors.push(this.$i18n.t("users.fields.first_name.required"));
      if (!this.$v.form.firstName.maxLength)
        errors.push(
          this.$i18n.t("users.fields.first_name.max_length", {
            max: this.$v.form.firstName.$params.maxLength.max
          })
        );
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.form.lastName.$dirty) return errors;

      if (!this.$v.form.lastName.required)
        errors.push(this.$i18n.t("users.fields.last_name.required"));
      if (!this.$v.form.lastName.maxLength)
        errors.push(
          this.$i18n.t("users.fields.last_name.max_length", {
            max: this.$v.form.lastName.$params.maxLength.max
          })
        );
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;

      if (!this.$v.form.email.email)
        errors.push(this.$i18n.t("users.fields.email.invalid"));
      if (!this.$v.form.email.maxLength)
        errors.push(this.$i18n.t("users.fields.email.invalid"));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.form.phone.$dirty) return errors;

      if (!this.$v.form.phone.matchPhone)
        errors.push(this.$i18n.t("users.fields.phone.invalid"));
      return errors;
    }
  }
};
</script>